@tailwind base;
@tailwind components;
@tailwind utilities;

@keyframes GradientBackground {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

@keyframes flip-words {
  0% {
    margin-top: -360px;
  }
  5% {
    margin-top: -270px;
  }
  25% {
    margin-top: -270px;
  }
  30% {
    margin-top: -180px;
  }
  50% {
    margin-top: -180px;
  }
  55% {
    margin-top: -90px;
  }
  75% {
    margin-top: -90px;
  }
  80% {
    margin-top: 0px;
  }
  99.99% {
    margin-top: 0px;
  }
  100% {
    margin-top: -360px;
  }
}

@layer utilities {
  .h-500 {
    height: 500px;
  }
  .hero-gradient {
    background: linear-gradient(45deg, #2563eb, #24ddeb, #2563eb);
    background-size: 600% 600%;
    animation: GradientBackground 10s ease infinite;
  }
  .flip-animation {
    animation: flip-words 10s cubic-bezier(0.23, 1, 0.32, 1.2) infinite;
  }
  .flip-item {
    margin-bottom: 45px;
    height: 45px;
  }
  .player-wrapper {
    position: relative;
    padding-top: 56.25%;
  }
}
